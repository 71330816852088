

























































import { Prop, Component, Mixins } from 'vue-property-decorator'

// mixins
import { BoughtLicense } from '@/mixins/cart'
import { ModalFunctions } from '~/mixins/modals'
import { BeatImageOrProducerLogo } from '~/mixins/beat-image-or-producer-logo'
import { BeatIsFullyBoughtAndBeatIsLiked } from '@/mixins/beat-is-liked'

// const
import { ModalType } from '~/store/modal'
import { BeatStore } from '~/store/beat'
import { UserStore } from '#/store/user'
import { AudioFileType } from '~/const/audio-file-type'

@Component
export default class BeatAction extends Mixins(BeatIsFullyBoughtAndBeatIsLiked, BoughtLicense, ModalFunctions, BeatImageOrProducerLogo) {
    @Prop({ type: Object }) beat: IBeat

    AudioFileType = AudioFileType
    ModalType = ModalType

    @BeatStore.Action('downloadFileType') downloadFileType: () => void
    @BeatStore.Action('openPdfContact') openPdfContact: ({ beatId }: { beatId: number }) => void
    @BeatStore.Action('demoDownloadBeat') vuexDemoDownloadBeat: (beat: IBeat) => void
    @UserStore.Action('toggleLike') vuexToggleLike: (beat: IBeat) => void

    demoDownloadBeat() {
        this.closeModal()
        this.vuexDemoDownloadBeat(this.beat)
    }

    toggleLike() {
        this.closeModal()
        this.vuexToggleLike(this.beat)
    }

    goToProducer() {
        this.$router.push({ name: 'Producer', params: { slug: this.beat.producer.slug } })
        this.closeModal()
    }

    goToArtistType() {
        this.$router.push({ name: 'ArtistType', params: { slug: this.beat.artist_type.slug } })
        this.closeModal()
    }
}
