var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "beatAction" }, [
    _c(
      "div",
      { staticClass: "beatAction__list" },
      [
        _c("div", { staticClass: "beatAction__item--flex" }, [
          _c("img", {
            staticClass: "beatAction__img",
            attrs: { src: _vm.beatImageOrProducerLogo(_vm.beat) },
          }),
          _c("div", [
            _c("div", { staticClass: "beatAction__title" }, [
              _vm._v(_vm._s(_vm.beat.name)),
            ]),
            _c("div", { staticClass: "beatAction__sub" }, [
              _vm._v(
                _vm._s(_vm.beat.bpm) +
                  " BPM - " +
                  _vm._s(
                    _vm.beat.producer.display_name
                      ? _vm.beat.producer.display_name
                      : ""
                  )
              ),
            ]),
          ]),
        ]),
        _c(
          "a",
          {
            staticClass: "beatAction__item",
            on: {
              click: function ($event) {
                return _vm.toggleLike()
              },
            },
          },
          [
            _c("icon", {
              staticClass: "beatAction__icon",
              attrs: { name: _vm.beatIsLiked ? "heart" : "heart-o" },
            }),
            _vm._v("Favorite"),
          ],
          1
        ),
        _c(
          "a",
          {
            staticClass: "beatAction__item",
            on: {
              click: function ($event) {
                return _vm.SET_ACTIVE_MODAL({
                  modal: _vm.ModalType.LicenseOptionSelection,
                  payload: _vm.beat,
                })
              },
            },
          },
          [
            _c("icon", {
              staticClass: "beatAction__icon",
              attrs: { name: "add-to-cart-o" },
            }),
            _vm._v("Add To Cart"),
          ],
          1
        ),
        _vm.$route.name != "Purchases" && _vm.beat.demo_download_allowed
          ? _c(
              "a",
              {
                staticClass: "beatAction__item",
                on: {
                  click: function ($event) {
                    return _vm.demoDownloadBeat()
                  },
                },
              },
              [
                _c("icon", {
                  staticClass: "beatAction__icon",
                  attrs: { name: "download-o" },
                }),
                _vm._v("Demo Download"),
              ],
              1
            )
          : _vm._e(),
        _vm.$route.name == "Purchases" && _vm.boughtLicense
          ? [
              _c(
                "a",
                {
                  staticClass: "beatAction__item",
                  on: {
                    click: function ($event) {
                      return _vm.downloadFileType({
                        beatId: _vm.beat.id,
                        fileType: _vm.AudioFileType.Mp3,
                      })
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "beatAction__icon",
                    attrs: { name: "mp3" },
                  }),
                  _vm._v("Download MP3"),
                ],
                1
              ),
              _vm.boughtLicense.wav
                ? _c(
                    "a",
                    {
                      staticClass: "beatAction__item",
                      on: {
                        click: function ($event) {
                          return _vm.downloadFileType({
                            beatId: _vm.beat.id,
                            fileType: _vm.AudioFileType.Wav,
                          })
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "beatAction__icon",
                        attrs: { name: "wav" },
                      }),
                      _vm._v("Download WAV"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.boughtLicense.trackout
                ? _c(
                    "a",
                    {
                      staticClass: "beatAction__item",
                      on: {
                        click: function ($event) {
                          return _vm.downloadFileType({
                            beatId: _vm.beat.id,
                            fileType: _vm.AudioFileType.Trackout,
                          })
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "beatAction__icon",
                        attrs: { name: "stems" },
                      }),
                      _vm._v("Download STEMS"),
                      _c("span", [_vm._v("(more than 200mb)")]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "beatAction__item",
                  on: {
                    click: function ($event) {
                      return _vm.openPdfContact({ beatId: _vm.beat.id })
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "beatAction__icon",
                    attrs: { name: "document-o" },
                  }),
                  _vm._v("Download Contract"),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm.$route.name !== "Producer"
          ? _c(
              "a",
              {
                staticClass: "beatAction__item",
                on: { click: _vm.goToProducer },
              },
              [
                _c("icon", {
                  staticClass: "beatAction__icon",
                  attrs: { name: "piano-o" },
                }),
                _vm._v("View Producer"),
              ],
              1
            )
          : _vm._e(),
        _vm.$route.name !== "ArtistType"
          ? _c(
              "a",
              {
                staticClass: "beatAction__item",
                on: { click: _vm.goToArtistType },
              },
              [
                _c("icon", {
                  staticClass: "beatAction__icon",
                  attrs: { name: "microphone-o" },
                }),
                _vm._v("View Artist Type"),
              ],
              1
            )
          : _vm._e(),
        _c(
          "a",
          {
            staticClass: "beatAction__item",
            on: {
              click: function ($event) {
                return _vm.SET_ACTIVE_MODAL({
                  modal: _vm.ModalType.Share,
                  payload: _vm.beat,
                })
              },
            },
          },
          [
            _c("icon", {
              staticClass: "beatAction__icon",
              attrs: { name: "share" },
            }),
            _vm._v("Share"),
          ],
          1
        ),
      ],
      2
    ),
    _c(
      "a",
      { staticClass: "beatAction__close", on: { click: _vm.closeModal } },
      [_vm._v("Cancel")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }