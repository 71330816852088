import { fetchOwnProducer } from '#/api/profile'
import set from 'lodash.set'

import { namespace } from 'vuex-class'
import { fetchOwnProfile } from '~/api/user'
import { VuexActionData } from '~/interfaces/interface-utils'

export const UserStore = namespace('user')

interface State {
	authenticatedUser: IUserProducer
	tasks: object[]
	toplineBanner: boolean
}

export interface ILodashSetParams {
	propertyPath: string
	value: any
}

export default {
	namespaced: true,
	state: {
		authenticatedUser: null,
		tasks: [],
		toplineBanner: true
	} as State,
	getters: {
		hasEliteSubscription: (state: State) => {
			return state.authenticatedUser?.subscription_type === 'Elite'
		},
		toplineBannerShow: (state: State, getters: any) => {
			return !getters.hasEliteSubscription && state.toplineBanner
		}
	},
	mutations: {
		SET_PRODUCER_USER_INFO: (state: State, payload: IUserProducer) => (state.authenticatedUser = payload),
		UPDATE_PRODUCER_PROPERTY_LOCALLY: (state: State, { propertyPath, value }: ILodashSetParams) => set(state.authenticatedUser, propertyPath, value),
		HIDE_TOPLINE_BANNER: (state: State) => (state.toplineBanner = false)
	},
	actions: {
		async fetchUserProducerInfo({ commit, dispatch }: VuexActionData<State>) {
			commit('modal/SET_LOADING_STATE', true, { root: true })
			const user = await fetchOwnProfile()
			// logout if not a producer
			if (user?.producer_account) {
				// fetch producer data & generate Obj for user profile
				const producer = await fetchOwnProducer()
				const userProfile = { ...user, ...producer }
				// remove properties
				const { producer_account, ...cleanedUserProfile } = userProfile
				commit('SET_PRODUCER_USER_INFO', cleanedUserProfile)
			} else {
				await dispatch('auth/logout', { redirectToLogin: true }, { root: true })
				console.error('User is not a producer')
			}
			commit('modal/SET_LOADING_STATE', false, { root: true }) // reset loading
		},
		resetAuthenticatedUser({ commit }: VuexActionData<State>) {
			commit('SET_PRODUCER_USER_INFO', null)
		}
	}
}
